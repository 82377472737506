// Generated by Framer (51f36d1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["pUyKlDhBy", "wsaXe3X4j"];

const serializationHash = "framer-cwn08"

const variantClassNames = {pUyKlDhBy: "framer-v-1h2g8fh", wsaXe3X4j: "framer-v-ecum8m"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {closed: "pUyKlDhBy", open: "wsaXe3X4j"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "pUyKlDhBy", W41POyFF1: click ?? props.W41POyFF1} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, W41POyFF1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "pUyKlDhBy", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1vi7i71 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("wsaXe3X4j")
})

const onTaprbbeed = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("pUyKlDhBy")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "wsaXe3X4j") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1h2g8fh", className, classNames)} data-framer-name={"closed"} data-highlight layoutDependency={layoutDependency} layoutId={"pUyKlDhBy"} onTap={onTap1vi7i71} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({wsaXe3X4j: {"data-framer-name": "open", onTap: onTaprbbeed}}, baseVariant, gestureVariant)}><motion.div className={"framer-hbj23q"} data-framer-name={"line3"} layoutDependency={layoutDependency} layoutId={"ymOeX6Wxt"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{wsaXe3X4j: {rotate: 45}}}/>{isDisplayed() && (<motion.div className={"framer-ezsz5h"} data-framer-name={"line2"} layoutDependency={layoutDependency} layoutId={"xuNm3wFwA"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>)}<motion.div className={"framer-w54kuo"} data-framer-name={"line 1"} layoutDependency={layoutDependency} layoutId={"agAVFu_RK"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{wsaXe3X4j: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cwn08.framer-poeal4, .framer-cwn08 .framer-poeal4 { display: block; }", ".framer-cwn08.framer-1h2g8fh { cursor: pointer; height: 31px; overflow: hidden; position: relative; width: 31px; }", ".framer-cwn08 .framer-hbj23q { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: 6px; width: 27px; }", ".framer-cwn08 .framer-ezsz5h { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: calc(51.61290322580647% - 2px / 2); width: 27px; }", ".framer-cwn08 .framer-w54kuo { bottom: 5px; flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; width: 27px; }", ".framer-cwn08.framer-v-ecum8m.framer-1h2g8fh { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 31px); }", ".framer-cwn08.framer-v-ecum8m .framer-hbj23q { top: calc(51.61290322580647% - 2px / 2); }", ".framer-cwn08.framer-v-ecum8m .framer-w54kuo { bottom: unset; top: calc(51.61290322580647% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wsaXe3X4j":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"W41POyFF1":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQ4E6XkrDv: React.ComponentType<Props> = withCSS(Component, css, "framer-cwn08") as typeof Component;
export default FramerQ4E6XkrDv;

FramerQ4E6XkrDv.displayName = "Navigation/hamburger";

FramerQ4E6XkrDv.defaultProps = {height: 31, width: 31};

addPropertyControls(FramerQ4E6XkrDv, {variant: {options: ["pUyKlDhBy", "wsaXe3X4j"], optionTitles: ["closed", "open"], title: "Variant", type: ControlType.Enum}, W41POyFF1: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerQ4E6XkrDv, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})